(function () {
  'use strict';
  // jscs:disable maximumLineLength

  angular
    .module('wbAuth')
    .config(config);

  function config($translateProvider) {
    $translateProvider.translations('hu', {
      'app.title': 'Rewin Kártyarendelés, feltöltés',

      'auth.title': 'Üdvözöljük a Rewin Kártyarendelés és feltöltés oldalán!',
      'auth.login.title': 'Belépés',
      'auth.login.subtitle': 'Meglévő partnereink részére',
      'auth.login.legend': 'Kérjük adja meg e-mail-címét,<br />amivel korábban regisztrált<br />és a hozzá tartozó jelszavát.',
      'auth.login.help.legend': 'Amennyiben problémája van a belépéssel, keresse ügyfélszolgálatunkat:',
      'auth.logout': 'Kilépés',
      'auth.identifier': 'E-mail / Kártyaszám',
      'auth.identifierTooltip': 'A kártya hátlapján található,<br /> 705xxxxxxx kezdetű, 10 jegyű azonosító',
      'auth.email': 'E-mail',
      'auth.username': 'Felhasználónév',
      'auth.password': 'Jelszó',
      'auth.passwordConfirm': 'Jelszó megerősítése',
      'auth.password.tooltip': 'A jelszónak legalább 8 karakter hosszúnak kell lennie <br />és tartalmaznia kell kis- és nagybetűt illetve számot is.',
      'auth.passwordReset': 'Elfelejtett jelszó',
      'auth.passwordReset.legend': 'Amennyiben elfelejtette jelszavát, úgy új jelszót kérhet a regisztrált e-mail-címét megadva. Az \'Elküld\' gombra kattintva rövid időn belül kiküldjük Önnek a jelszó helyreállításához szükséges információkat.',
      'auth.passwordReset.send': 'Elküld',
      'auth.passwordReset.back': 'Vissza',
      'auth.passwordReset.success': 'Kérését megkaptuk, új jelszavát hamarosan kiküldjük az e-mail-címére.',
      'auth.passwordReset.confirm.legend': 'Köszönjük! Új jelszó igényét fogadtuk és hamarosan kiküldjük e-mail-címére.',
      'passwordResetConfirmDialog.title': 'Hiba történt új jelszavának elkészítése közben',
      'passwordResetConfirmDialog.content': 'Kérjük, kérjen új jelszót és kattintson a küldött e-mailben található linkre.',
      'passwordResetConfirmDialog.button.ok': 'ok',
      'auth.password.change': 'Jelszóváltoztatás',
      'card.password.change.legend': 'Amennyiben szeretné megváltoztatni jelszavát, itt megteheti. Kérjük, először adja meg jelenlegi jelszavát, ezt követően pedig az új jelszót.',
      'auth.password.change.password': 'Jelenlegi jelszó',
      'auth.password.change.passwordNew': 'Új jelszó',
      'auth.password.change.passwordConfirm': 'Új jelszó megerősítése',
      'auth.password.change.submit': 'Megváltoztatom a jelszavam',
      'auth.password.change.success': 'Sikeresen megváltoztattuk a jelszavát!',
      'auth.registration.title': 'Regisztráció',
      'auth.registration.subtitle': 'Új ügyfelek részére',
      'auth.steps.first': 'Regisztráljon gyorsan és egyszerűen!',
      'auth.steps.second': 'Igényeljen Edenred kártyát rendszerünkben!',
      'auth.steps.third': 'Majd töltse fel kártyáját 2 perc alatt!',
      'auth.legalNotice.title': 'Adatkezelési Tájékoztató',
      'auth.legalNotice.url': 'https://adatvedelem.edenred.hu/adatkezelesi-tajekoztato/',
      'auth.privacyPolicy.title': 'ÁSZF',
      'auth.privacyPolicy.url': 'https://aszf.edenred.hu/aszf/munkaltato/',
      'auth.imprint.title': 'Impresszum',
      'auth.imprint.url': 'http://edenred.hu/hu/impresszum',
      'auth.copyrightNotice': 'Minden jog fenntartva.',

      'auth.companySelector': 'Üdvözöljük!',
      'auth.companySelector.legend': 'Az Ön fiókjához több cég tartozik, kérem válasszon az alábbiak közül.',
      'auth.companySelector.send': 'Belépés',
      'auth.companySelector.selectedCompany': 'Választott cég',
      'auth.companySelector.pleaseSelect': 'Kérem, válasszon ki egy céget',

      'auth.login.firstLoginDialog.content': 'Tisztelt Ügyfelünk!<br><br>Az online kártyarendelő felületünk megújult, mostantól az edenredonline.hu oldalon tudja intézni kártyás rendeléseit.<br>Amennyiben bármi kérdése van, kérje az Ügyfélszolgálatunk segítségét a 06-1/413-3333-as telefonszámon.<br><br>Üdvözlettel:<br>Az Edenred csapata',
      'auth.firstLogin.button': 'Rendben',
      'auth.sessionTimeout.content': 'A fiókjában 30 perce nem észleltünk aktivitást, a rendszer 1 perc múlva automatikusan ki fogja léptetni.',
      'auth.sessionTimeout.ok': 'Belépve maradok',
      'auth.sessionTimeout.cancel': 'Mégse'
    });
  }
}());
